import Image from '@/components/atoms/Image';
import styles from './poweredByVaypol.module.scss';
import Link from '@/components/atoms/Link';

const ProweredByVaypol = () => {
  return (
    <div className={styles.powered}>
      <span>Powered By</span>
      <Link
        href="https://www.vaypol.com.ar/"
        target="_blank"
      >
        <Image
          src="/brandlogos/vaypol.webp"
          alt="VAYPOL"
          width={100}
          height={15}
        />
      </Link>
    </div>
  );
};
export default ProweredByVaypol;
