'use client';

import { useSelector } from 'react-redux';
import NextLink from 'next/link';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { usePathname, useRouter } from 'next/navigation';
import styles from './link.module.scss';
import { PageSpinner } from '@/components/molecules';

const seen = new Set();

function sleep(ms) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function prefetchImages(href, isDesktop) {
  const imageResponse = await fetch(
    `/api/prefetch-images${href}?isDesktop=${isDesktop}`,
    {
      priority: 'low'
    }
  );
  // only throw in dev
  if (!imageResponse.ok && process.env.NODE_ENV === 'development') {
    return;
  }
  const { images } = await imageResponse.json();

  return images || [];
}

function prefetchImage(image) {
  if (image.loading === 'lazy' || seen.has(image.src)) {
    return;
  }
  const img = new Image();
  img.decoding = 'async';
  img.fetchPriority = 'low';
  seen.add(image.src);
  img.src = image.src;
  img.alt = image.alt;
  let done = false;
  img.onload = () => {
    done = true;
  };
  img.onerror = () => {
    done = true;
  };
  return () => {
    if (done) return;
    img.src = '';
    seen.delete(image.src);
  };
}
const Link = (props) => {
  const {
    children,
    altText,
    href,
    allWidth = false,
    target = '_self',
    primary = false,
    className = '',
    linkStyle = {},
    center = false,
    handleClick = () => {},
    absolutePath = false,
    shouldRedirect = null,
    noWidth = false,
    prefetch = false,
    ...rest
  } = props;
  const [images, setImages] = useState([]);
  const [preloading, setPreloading] = useState([]);
  const linkClass = cn({
    [styles.link]: true,
    [styles.primary]: primary,
    [styles.allWidth]: allWidth,
    [styles.center]: center,
    [className]: !!className
  });
  const isDesktop = useSelector((state) => state.machineInformation.isDesktop);

  const router = useRouter();
  const linkRef = useRef(null);
  const path = usePathname();
  const [loading, setLoading] = useState(false);
  let prefetchTimeout = null;
  let store = useSelector((state) => state.store);
  if (store === process.env.NEXT_PUBLIC_STORE_ID) store = undefined;
  const handleLinkClick = (e) => {
    e.preventDefault();
    if (
      shouldRedirect &&
      typeof shouldRedirect === 'function' &&
      shouldRedirect(e)
    ) {
      return;
    } else if (handleClick) {
      handleClick(e);
    }

    e.preventDefault();
    const newUrl = !absolutePath && store ? `/${store}${href}` : href;
    if (path !== newUrl) {
      setLoading(true);
      router.push(!absolutePath && store ? `/${store}${href}` : href);
    }
  };
  const isProduction = process.env.NEXT_PUBLIC_MODE_ENV === 'production';

  useEffect(() => {
    if (!prefetch || !isProduction) {
      return;
    }

    const linkElement = linkRef.current;
    if (!linkElement) return;

    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          prefetchTimeout = setTimeout(async () => {
            router.prefetch(String(href));
            await sleep(0);

            const imgs = await prefetchImages(
              href === '/' ? '' : String(href),
              isDesktop
            );
            setImages(imgs);
            observer.unobserve(entry.target);
          }, 300);
        } else if (prefetchTimeout) {
          clearTimeout(prefetchTimeout);
          prefetchTimeout = null;
        }
      },
      { rootMargin: '0px', threshold: 0.1 }
    );

    observer.observe(linkElement);

    return () => {
      observer.disconnect();
      if (prefetchTimeout) {
        clearTimeout(prefetchTimeout);
      }
    };
  }, [href, prefetch]);

  useEffect(() => {
    setLoading(false);
  }, [path]);

  return (
    <NextLink
      ref={linkRef}
      prefetch={false}
      title={altText}
      target={target}
      href={!absolutePath && store ? `/${store}${href}` : href}
      className={allWidth ? styles.allWidth : undefined}
      onClick={handleLinkClick}
      nowidth={noWidth ? 'true' : 'false'}
      onMouseEnter={() => {
        if (isProduction && prefetch) {
          router.prefetch(String(href));
          if (preloading.length) return;
          const p = [];

          // eslint-disable-next-line no-restricted-syntax
          for (const image of images) {
            const remove = prefetchImage(image);
            if (remove) p.push(remove);
          }
          setPreloading(p);
        }
      }}
      onMouseLeave={() => {
        if (isProduction && prefetch) {
          // eslint-disable-next-line no-restricted-syntax
          for (const remove of preloading) {
            remove();
          }
          setPreloading([]);
        }
      }}
      {...rest}
    >
      <div
        className={linkClass}
        style={{ ...linkStyle }}
      >
        {children}
      </div>
      {loading && <PageSpinner />}
    </NextLink>
  );
};

Link.propTypes = {
  href: PropTypes.string.isRequired,
  altText: PropTypes.string,
  children: PropTypes.node.isRequired,
  allWidth: PropTypes.bool,
  target: PropTypes.string,
  primary: PropTypes.bool,
  className: PropTypes.string,
  linkStyle: PropTypes.object,
  center: PropTypes.bool,
  handleClick: PropTypes.func,
  absolutePath: PropTypes.bool,
  shouldRedirect: PropTypes.func,
  noWidth: PropTypes.bool,
  prefetch: PropTypes.bool
};

export default Link;
