import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { Text } from '@/components/atoms';

const Copyright = ({ h1InFooter }) => {
  const storeId = process.env.NEXT_PUBLIC_STORE_ID;
  return (
    <div className={styles.infoContainer}>
      <Text
        id="copyright"
        textSize="xs"
        textColor="var(--color-white-absolute)"
        className={styles.copy}
      >
        @Copyright - 2017 <br />
        <strong>
          {' '}
          {storeId === 'city' ? 'www.somoscity.com' : 'www.vaypol.com'}
          <br />
          TODOS LOS DERECHOS RESERVADOS.
        </strong>{' '}
        <br />
        <br />
        Está prohibida la reproducción total o parcial del contenido de esta
        pagina web, sin la expresa autorizacion de la administracion de la
        tienda virtual.
        <br />
        <br /> Provincia de Mendoza - Argentina. City, empresa perteneciente al
        grupo Mabel Salinas S.A. con domicilio en Belgrano 1059 - Tupungato -
        Provincia de Mendoza – Argentina.
      </Text>
      {h1InFooter && <h1 className={styles.title}>{h1InFooter}</h1>}
    </div>
  );
};
Copyright.propTypes = { h1InFooter: PropTypes.string };
Copyright.defaultProps = { h1InFooter: '' };
export default Copyright;
