import dynamic from 'next/dynamic';

export const BrandImage = dynamic(() => import('./BrandImage'));
export const MaxContainer = dynamic(() => import('./MaxContainer'));
export const Button = dynamic(() => import('./Button'));
export const Container = dynamic(() => import('./Container'));
export const Divider = dynamic(() => import('./Divider'));
export const Icon = dynamic(() => import('./Icon'));
export const Image = dynamic(() => import('./Image'));
export const Input = dynamic(() => import('./Input'));
export const Link = dynamic(() => import('./Link'));
export const Share = dynamic(() => import('./Share'));
export const OptionItem = dynamic(() => import('./Select/OptionItem'));
export const Select = dynamic(() => import('./Select'));
export const Text = dynamic(() => import('./Text'));
export const HiddenText = dynamic(() => import('./HiddenText'));
export const PopUp = dynamic(() => import('./PopUp'));

export const CheckoutStylesWrapper = dynamic(() =>
  import('./CheckoutStylesWrapper')
);
export const SvgRectangles = dynamic(() => import('./SvgRectangles'));
export const Space = dynamic(() => import('./Space'));
