import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './button.module.scss';
import Text from '@/components/atoms/Text';
import Spinner from '@/components/molecules/Spinner';

const Button = (props) => {
  const {
    br = 'soft',
    border = '',
    badge = '',
    className = '',
    endIcon = null,
    id = '',
    justify = 'center',
    label = '',
    onClick = () => {},
    startIcon = null,
    submit = false,
    textCase = 'none',
    textColor = 'black',
    textStyle = {},
    textWeight = 'normal',
    variant = 'transparent',
    width = 'fullWidth',
    withOutPadding,
    ariaLabel = '',
    style = {},
    loading = false,
    key = ''
  } = props;
  const buttonClass = cn({
    [styles.button]: true,
    [styles[variant]]: true,
    [styles.brHard]: br === 'hard',
    [className]: !!className
  });

  const widthOptions = {
    fullWidth: '100%',
    none: 'auto',
    sm: '25%',
    md: '50%',
    lg: '75%'
  };

  // TODO: agregar spinner cuando cargue la accion

  return (
    <button
      key={key}
      type={submit ? 'submit' : 'button'}
      aria-label={ariaLabel || label || 'Icono de acción'}
      id={id}
      onClick={onClick}
      className={buttonClass}
      disabled={variant === 'disabled' || loading}
      style={{
        width: `${widthOptions[width]}`,
        justifyContent: justify,
        padding: withOutPadding ? '0px' : ' 15px 25px',
        border: border ? `1px solid ${border}` : undefined,
        ...style
      }}
    >
      {startIcon}
      {label && !loading && (
        <Text
          weight={textWeight}
          textCase={textCase}
          textColor={
            variant === 'disabled' ? 'var(--color-grey-lighter)' : textColor
          }
          textStyle={textStyle}
          variant="span"
          textSize="s"
        >
          {label}
        </Text>
      )}
      {endIcon}
      {badge && (
        <div
          className={styles.badge}
          style={{ backgroundColor: 'white' }}
        >
          <Text
            variant="span"
            weight="semibold"
            textColor="black"
            textSize="m"
          >
            {badge}
          </Text>
        </div>
      )}
      {loading && (
        <Spinner
          className={styles.loader}
          button
        />
      )}
    </button>
  );
};

Button.propTypes = {
  loading: PropTypes.bool,
  id: PropTypes.string,
  border: PropTypes.string,
  badge: PropTypes.string,
  /**
   * Las variantes del botón son:
   */
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'terciary',
    'transparent',
    'icon',
    'disabled',
    'transparent-disabled'
  ]),
  /**
   * Cambia el texto (en caso de existir) según:
   */
  textCase: PropTypes.oneOf(['uppercase', 'capitalize', 'none']),
  /**
   * Cambia el peso de las letras a:
   */
  textWeight: PropTypes.oneOf(['bold', 'bolder', 'normal']),
  /**
   * Cambia el color del texto:
   */
  textColor: PropTypes.string,
  /**
   * Cambia el stilo del texto:
   */
  textStyle: PropTypes.object,
  /**
   * El botón renderiza un texto dentro si se le pasa:
   */
  label: PropTypes.string,
  /**
   * Aria label del boton:
   */
  ariaLabel: PropTypes.string,
  /**
   * El botón ejecuta la función que se le pase por el evento onClick:
   */
  onClick: PropTypes.func,
  /**
   * Se renderiza un ícono dentro del botón ubicado a la izquierda:
   */
  startIcon: PropTypes.node,
  /**
   * Se renderiza un ícono dentro del botón ubicado a la derecha:
   */
  endIcon: PropTypes.node,
  /**
   * Ésta prop cambia la intensidad del borde:
   */
  br: PropTypes.oneOf(['soft', 'hard']),
  /**
   * Ésta prop cambia el tamaño del botón a medidas estándars:
   */
  width: PropTypes.oneOf(['none', 'sm', 'md', 'lg', 'fullWidth']),
  /**
   * El botón trae padding por defecto, con ésta prop se eliminan:
   */
  withOutPadding: PropTypes.bool,
  /**
   * Cuando el botón renderiza hijos internamente, éstos se disponen con flex según éstos parámetros:
   */
  justify: PropTypes.oneOf([
    'center',
    'flex-start',
    'flex-end',
    'space-between'
  ]),
  /**
   * Si se quiere que el botón sea del tipo submit se le debe pasar ésta prop como true:
   */
  submit: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  key: PropTypes.string
};

export default Button;
