import PropTypes from 'prop-types';
import Info from './Info';
import Newsletter from './Newsletter';
import Safety from './Safety';
import ProweredByVaypol from '@/components/molecules/PoweredByVaypol';
import Contact from './Contact';
import Copyright from './Copyright';
import styles from './footer.module.scss';

const storeId = process.env.NEXT_PUBLIC_STORE_ID;

const Footer = ({
  isHomePage = false,
  h1InFooter = '',
  withoutNewsletter = false
}) => {
  return (
    <div>
      {!withoutNewsletter && <Newsletter />}
      <div className={styles.footerLayout}>
        <Info isHomePage={isHomePage} />
        <Contact />
      </div>
      <div
        style={{
          background:
            storeId === 'city' ? 'var(--color-footer-bg)' : 'var(--color-black)'
        }}
        className={styles.footerLayout}
      >
        <Safety isHomePage={isHomePage} />
        <Copyright h1InFooter={h1InFooter} />
        {storeId === 'city' && <ProweredByVaypol />}
      </div>
    </div>
  );
};

Footer.propTypes = {
  isHomePage: PropTypes.bool,
  h1InFooter: PropTypes.string,
  withoutNewsletter: PropTypes.bool
};

export default Footer;
