/* eslint-disable arrow-body-style */
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Image, Link, Text } from '@/components/atoms';
import styles from './safety.module.scss';
import datafiscal from '@/public/logos/datafiscal.png';
import payWay from '@/public/logos/paywayLogo.png';
import naturalRace from '@/public/assets/naturalrace-logo.svg';

const Safety = ({ isHomePage = false }) => {
  const storeId = useSelector((state) => state.store);
  return (
    <div className={styles.wrapper}>
      <div className={styles.flexWrapper}>
        {isHomePage && storeId === 'vaypol' && (
          <a
            href="https://www.naturalrace.com.ar/"
            className={styles.event}
          >
            <Image
              src={naturalRace.src}
              alt="Carrera Naturalrace"
              fill
            />
          </a>
        )}
        <div className={styles.button}>
          <div className={styles.safeLogos}>
            <Link
              absolutePath
              href="http://qr.afip.gob.ar/?qr=akvEeInnILv5TslEKVLNQQ,,"
              target="_blank"
            >
              <div className={styles.img}>
                <Image
                  alt="datafiscal logo"
                  src={datafiscal.src}
                  heightSkeleton="50px"
                  widthSkeleton="60px"
                  fill
                  style={{ width: '100%', height: '100%' }}
                />
              </div>
            </Link>
            <Link
              absolutePath
              href="https://www.payway.com.ar/vender-online"
              target="_blank"
            >
              <div className={styles.img}>
                <Image
                  alt="payWay logo"
                  src={payWay.src}
                  heightSkeleton="50px"
                  widthSkeleton="60px"
                  fill
                  style={{ width: '100%', height: '100%' }}
                />
              </div>
            </Link>
          </div>
          <Text
            textColor="var(--color-primary)"
            variant="span"
            textSize="s"
            textStyle={{ textAlign: 'center' }}
          >
            COMPRA 100% SEGURA
          </Text>
        </div>
      </div>
    </div>
  );
};
Safety.propTypes = { isHomePage: PropTypes.bool };

export default Safety;
