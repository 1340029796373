'use client';

/* eslint-disable import/no-mutable-exports */
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import variables from '@/styles/variables';

const useDeviceType = () => {
  const isDesktopServerSide = useSelector(
    (state) => state.machineInformation.isDesktop
  );
  const [isDesktop, setIsDesktop] = useState(isDesktopServerSide);
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > variables.desktop);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return {
    isDesktop
  };
};

export default useDeviceType;
