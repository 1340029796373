import Link from '@/components/atoms/Link';
import { Text } from '@/components/atoms';
import styles from '../Info/index.module.scss';

const brands = {
  vaypol: [
    { id: 1, name: 'New Balance', url: 'new-balance' },
    { id: 2, name: 'Kappa', url: 'kappa' },
    { id: 3, name: 'Salomon', url: 'salomon' },
    { id: 4, name: 'Asics', url: 'asics' },
    { id: 5, name: 'DRB', url: 'drb' }
  ],
  city: [
    { id: 1, name: 'adidas', url: 'adidas-originals' },
    { id: 2, name: 'Vans', url: 'vans' },
    { id: 3, name: 'Lacoste', url: 'lacoste' },
    { id: 4, name: 'Converse', url: 'converse' },
    { id: 5, name: 'Reebok', url: 'reebok' }
  ]
};

const Brands = () => {
  const storeId = process.env.NEXT_PUBLIC_STORE_ID;
  const renderBrands = () => {
    return brands[storeId].map((brand) => (
      <li key={brand.id}>
        <Link
          href={`/productos/${brand.url}${
            brand.url.includes('/p/1') ? '' : '/p/1'
          }`}
        >
          <Text
            textStyle={{ marginBottom: '.5rem' }}
            className={styles.active}
            textColor="var(--color-footer-text)"
            variant="span"
            textSize="xs"
          >
            {brand.name}
          </Text>
        </Link>
      </li>
    ));
  };

  return (
    <section className={styles.container}>
      <ul className={styles.brands}>{renderBrands()}</ul>
    </section>
  );
};

export default Brands;
