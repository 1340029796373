import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { Spinner } from '@/components/molecules';

const Skeleton = ({ children, withSpinner, elements = 1 }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      {Array(Math.ceil(elements))
        .fill(0)
        ?.map((_x, i) => (
          <div
            className={styles.container}
            // eslint-disable-next-line react/no-array-index-key
            key={i}
          >
            {children}
            {withSpinner && (
              <div className={styles.loader}>
                <Spinner />
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

Skeleton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  withSpinner: PropTypes.bool,
  elements: PropTypes.number
};
export default Skeleton;
