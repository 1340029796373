/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setActive } from '@/redux/faqs/faqsReducer';
import { Link, Text } from '@/components/atoms';
import styles from './index.module.scss';
import Brands from '../Brands';

const SectionItem = ({
  href = '',
  slug = '',
  sectionSlug = '',
  title = '',
  onClick = () => {},
  absolutePath = false
}) => (
  <div
    key={slug}
    onClick={onClick}
  >
    <Link
      key={slug}
      href={href || `/ayuda/${sectionSlug}`}
      absolutePath={absolutePath}
      prefetch
    >
      <Text
        textStyle={{ marginBottom: '.5rem' }}
        className={styles.active}
        textColor="var(--color-footer-text)"
        variant="span"
        textSize="xs"
      >
        {title}
      </Text>
    </Link>
  </div>
);

const Section = ({ title = '', children = null, className = '' }) => (
  <div className={`${styles.section} ${className}`}>
    <Text
      weight="bolder"
      variant="h4"
      textSize="m"
      textStyle={{ marginBottom: '1rem', textTransform: 'capitalize' }}
      textColor="var(--color-footer-text)"
    >
      {title}
    </Text>
    <div>{children}</div>
  </div>
);

const Info = ({ isHomePage = false }) => {
  const faqs = useSelector((state) => state.faqs);
  const help = useSelector((state) => state.help);
  const dispatch = useDispatch();
  return (
    <div className={styles.info}>
      <Section title="Información">
        {faqs?.data.map((i) => (
          <SectionItem
            key={i.title}
            slug={i.slug}
            sectionSlug={i.section_slug}
            title={i.title}
            onClick={() => dispatch(setActive(i.slug))}
          />
        ))}
        <SectionItem
          key="Nuestros valores"
          href="/nuestros-valores"
          title="Nuestros valores"
        />
        <SectionItem
          key="Partners"
          href="/partners"
          title="Partners"
        />
        <SectionItem
          key="Puntos de Retiro"
          href="/ayuda/sucursales"
          title="Puntos de Retiro"
        />
        <SectionItem
          key="Botón de arrepentimiento"
          href="tel:0810 666 0945"
          title="Botón de arrepentimiento"
          absolutePath
        />
      </Section>
      <Section title="Ayuda">
        {help?.data?.map((i) => (
          <SectionItem
            key={i.title}
            slug={i.slug}
            sectionSlug={i.section_slug}
            title={i.title}
            onClick={() => dispatch(setActive(i.slug))}
          />
        ))}

        <SectionItem
          key="Pagos y promociones"
          href="/promos"
          title="Pagos y promociones"
        />
      </Section>
      {isHomePage && (
        <Section title="Marcas Destacadas">
          <Brands />
        </Section>
      )}
    </div>
  );
};

Info.propTypes = {
  isHomePage: PropTypes.bool
};

SectionItem.propTypes = {
  href: PropTypes.string,
  slug: PropTypes.string,
  sectionSlug: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  absolutePath: PropTypes.bool
};

Section.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string
};

export default Info;
