import { ImFacebook2, ImTwitter, ImYoutube } from 'react-icons/im';
import { BsInstagram } from 'react-icons/bs';
import { FaTiktok } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import styles from './index.module.scss';
import { Divider, Link, Text } from '@/components/atoms';

const isCity = process.env.NEXT_PUBLIC_STORE_ID === 'city';

const Contact = () => {
  const help = useSelector((state) => state.help);
  return (
    <div
      style={!isCity ? { paddingTop: 0, alignSelf: 'center' } : {}}
      className={styles.contact}
    >
      <div className={styles.firstLine}>
        <Text
          weight="bold"
          variant="h4"
          textSize="xxl"
          textStyle={{ marginRight: '1rem' }}
          textColor="var(--color-footer-text)"
        >
          Contacto
        </Text>
        <Divider
          size="50"
          color="var(--color-footer-text)"
        />
      </div>
      <div className={styles.secondLine}>
        <Link
          absolutePath
          href="tel: 0810 666 0945"
        >
          <div className={styles.phone}>
            <Text
              weight="bold"
              variant="span"
              textSize="l"
              textColor="var(--color-footer-text)"
            >
              0810 666 0945
            </Text>
            <Text
              weight="semibold"
              variant="span"
              textSize="xs"
              textColor="var(--color-footer-text)"
            >
              Lun a Vie de 8:30 a 17.30hs
            </Text>
          </div>
        </Link>
        {help?.socials && Object.keys(help?.socials).length > 0 && (
          <div className={styles.logos}>
            {help?.socials?.facebook && (
              <Link
                absolutePath
                href={help.socials.facebook}
                target="_blank"
                altText="Facebook"
              >
                <ImFacebook2
                  fontSize={24}
                  color="var(--color-footer-text)"
                />
              </Link>
            )}
            {help?.socials?.instagram && (
              <Link
                absolutePath
                href={help.socials.instagram}
                target="_blank"
                altText="Instagram"
              >
                <BsInstagram
                  fontSize={24}
                  color="var(--color-footer-text)"
                />
              </Link>
            )}

            {help?.socials?.youtube && (
              <Link
                absolutePath
                href={help.socials.youtube}
                target="_blank"
                altText="Youtube"
              >
                <ImYoutube
                  fontSize={24}
                  color="var(--color-footer-text)"
                />
              </Link>
            )}
            {help?.socials?.tiktok && (
              <Link
                absolutePath
                href={help.socials.tiktok}
                target="_blank"
                altText="Tiktok"
              >
                <FaTiktok
                  fontSize={24}
                  color="var(--color-footer-text)"
                />
              </Link>
            )}
            {help?.socials?.twitter && (
              <Link
                absolutePath
                href={help.socials.twitter}
                target="_blank"
                altText="Twitter"
              >
                <ImTwitter
                  fontSize={24}
                  color="var(--color-footer-text)"
                />
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Contact;
