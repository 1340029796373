/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [], active: '' };

const faqsSlice = createSlice({
  name: 'faqs',
  initialState,
  reducers: {
    setActive(state, action) {
      state.active = action.payload;
    },
    setFaqs(_state, action) {
      return { ..._state, ...action.payload };
    }
  }
});
export const { setActive, setFaqs } = faqsSlice.actions;
export default faqsSlice.reducer;
